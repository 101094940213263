import request from "@/js/request";
import { queryParams } from "../js/util";
// 获取错误模具列表
export function getCustomer(data) {
  const newPars = queryParams(data);
  return request.get(`/customer${newPars}`);
}

export function addCustomer(data) {
  return request.post("/customer", data);
}

export function editCustomer(data) {
  return request.put("/customer", data);
}

// 删除用户
export function deleteCustomer(data) {
  const newPars = queryParams(data);

  return request.delete(`/customer${newPars}`);
}

// 生成设备编号
export function generateDeviceId(data) {
  return request.post("/device/build", data);
}
// 删除设备
export function deleteDeviceId(data) {
  const newPars = queryParams(data);
  return request.delete(`/device${newPars}`);
}
// 获取生成设备编号时所需要的选项
export function getGenerateOptionList() {
  return request.get(`/device`);
}
export function adddevice(data) {
  return request.post(`/device`, data);
}
// 获取序列号
export function getSerial(data) {
  const newPars = queryParams(data);
  return request.get(`/device${newPars}`);
}

// 新增序列号
export function addSerial(data, param, lang) {
  return request({
    url: "/company/authorize?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 删除序列号
export function deleteSerial(data, param, lang) {
  return request({
    url: "/company/cancel?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 编辑设备备注

export function editRemark(data) {
  return request({
    url: "/device",
    data,
    method: "put",
  });
}

// 转移设备
export function convertEquipment(data) {
  return request({
    url: "/device/transfer",
    data,
    method: "post",
  });
}

// 获取设备数据 （二级列表）
export function getDevices(data) {
  const newPars = queryParams(data);
  return request.get(`/devices${newPars}`);
}

export function exportDevices(data) {
  const newPars = queryParams(data);
  // return "https://yudata.apiadmin.yudotmc.com" + `/export${newPars}`;
  return "https://apiadmin.yudata.yudoplatform.com" + `/export${newPars}`;
}

//云端服务授权
export function auth(data) {
  return request.put("/device/auth", data);
}
